import schttp from 'public/src/services/schttp'
import { Toast } from '@shein/sui-mobile'
import { isLogin } from 'public/src/pages/common/utils/index.js'
const { template, parseQueryString, } = require('@shein/common-function')
import { daEventCenter } from 'public/src/services/eventCenter'
import { getAllCatIdsFromParentsTree } from 'public/src/pages/goods_detail/utils/productDetail.js'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })
import { debounce } from '@shein/common-function'

const beautyToast = (str) => {
  return Toast({
    htmlString: `<div style="padding: 12px 10px">${str}</div>`
  })
}
export const triggerSubmitBuyNow = ({
  goods_id: goods_id = '', // goods_id
  mall_code: mall_code = '', // mall_code
  quickship_tp: quickship_tp = '', // 当无quickship标签时传0，当支持quickship时传1，当不支持quickship时传3
  sku_code: sku_code = '', // sku_code
  sku_id: sku_id = '', // 
  result: result = 0, // 结果  成功发1，当失败发0
  result_reason: result_reason = '', // 当为报错码时，取报错码（考虑到文案过长） 当未选择销售属性时，取"Please select size" 当未登录时，取"Not Login"
  location: location = '', // page popup
  is_buynow: is_buynow = '', // 是否满足立即购买要求
  nobuynow_reason: nobuynow_reason = '', // 不满足立即购买原因，多个原因不满足时，用逗号分割 非免邮，取 0  价格不满足条件，取 1 未在圈品范围，取 2 举例，非免邮且价格不满足时，取 0,1
}) => {
  daEventCenter.triggerNotice({
    daId: '1-6-1-192',
    extraData: {
      activity_from: 'main',

      goods_id,
      mall_code,
      sku_code,
      sku_id,

      quickship_tp,
      result,
      result_reason,
      location,
      is_buynow,
      nobuynow_reason,
    }
  })
}

const triggerBuyNowEligshow = debounce({
  func(goodsId) {
    daEventCenter.triggerNotice({
      daId: '1-6-1-199',
      extraData: {
        goods_id: goodsId
      }
    })
  },
  wait: 2000 
})

/**
 * @function 立即购买
 * @return http /api/checkout/postRapidBatchAddCart/create result
*/
export const buyNow = async function ({
  language: language = {},
  httpSuccessCallBack: httpSuccessCallBack = null,
  needJump: needJump = false,

  mall_code: mall_code = '',
  promotion_id: promotion_id = '',
  promotion_type: promotion_type = '',
  quantity: quantity = '',
  sku_code: sku_code = '',
  checkout_total: checkout_total = '',
  checkout_no: checkout_no = '',
  buyNow: buyNow = '',
  daData: daData = {
    goods_id: '',
    quickship_tp: '0',
    sku_id: '',
    location: 'page',
    is_buynow: '1',
    nobuynow_reason: ''
  }
}) {
  const commonDaData = {
    sku_code,
    mall_code
  }
  if (!isLogin()) {
    let buyNowArgs = arguments[0]
    triggerSubmitBuyNow({ ...daData, ...commonDaData, result: 0, result_reason: 'Not Login' })
    return SHEIN_LOGIN.show({
      show: true,
      cb: () => {
        buyNow(buyNowArgs)
      },
    })
  }
  

  const add_source = parseQueryString(location.search)?.add_source

  const res = await schttp({
    method: 'POST',
    url: '/api/checkout/postRapidBatchAddCart/create',
    data: {
      product_list: [{
        mall_code,
        ...( promotion_id ? { promotion_id } : {}),
        ...( promotion_type ? { promotion_type } : {}),
        quantity,
        sku_code,
      }],
      checkout_no,
      add_source: add_source ? `detail_${add_source}` : '',
    },
  })
  const { info, tips } = res || {}
  let code = Number(res.code)
  if (code !== 0) {
    triggerSubmitBuyNow({ ...daData, ...commonDaData, result: 0, result_reason: code })
  }

  switch (code) {
    case 500304:
    case 500305:
      beautyToast(language.SHEIN_KEY_PWA_17323)
      break
  
    case 500303:
    case 500306:
    case 500302:
    case 300417:
      beautyToast(template(info.limitCount || '', info?.resultLimit || 0, info?.remainNum || 0, tips))
      break

    case 300405:
    case 300714:
    case 302444:
      beautyToast(template(info.limitCount, language.SHEIN_KEY_PWA_16138))
      break
    
    case 300402:
      beautyToast(language.SHEIN_KEY_PWA_28614)
      break
    
    case 200401:
    case 500301:
      beautyToast(language.SHEIN_KEY_PWA_15024)
      break

    case 300407:
      beautyToast(language.SHEIN_KEY_PWA_14969)
      break
    
    case 0:
      // 领券
      if (httpSuccessCallBack) {
        await httpSuccessCallBack()
      }
      triggerSubmitBuyNow({ ...daData, ...commonDaData, result: 1, result_reason: '' })
      if (needJump) {
        // 立即购满足abt，跳转下单页之前给当前页面设置buyNow参数
        if(buyNow){
          const url = new URL(window.location)
          url.searchParams.set('buyNow', buyNow)
          window.history.replaceState({}, '', url)
        }
        // 跳结算
        sessionStorage.setItem('cartPoints', JSON.stringify({
          checkout_total,
          promotion: promotion_id,
          checkout_from: 'buy_now'
        }))
        sessionStorage.setItem('buyNowFromUrl', location.href)
        schttp({
          method: 'POST',
          url: '/api/common/busineseCache/update',
          data: {
            cacheData: location.href,
            scanType: 'buyNowFromUrl',
          }
        }).finally(() => {
          location.href = `${gbCommonInfo.langPath}/checkout?checkout_no=${info?.checkoutNo}&page_from=page_goods_detail`
        })
      }
      break

    default:
      beautyToast(tips || language.SHEIN_KEY_PWA_15282)
      break
  }
  
  return res
}

const { DETAIL_BUY_NOW_TSP_LABEL, BUY_NOW_THRESHOLD  } = gbCommonInfo.DETAIL_SECOND_CONFIG || {}
const cacheCats = {}
export const showBuyNow = ({
  goodsId: goodsId = '',
  DetailBuynowAbt: DetailBuynowAbt = {},
  isSoldOut: isSoldOut = false,
  oneClickPayStateSupport: oneClickPayStateSupport = false,
  isCustomization: isCustomization = false,
  tsp: tsp = {},
  isFreeShippin: isFreeShippin = false,
  getEstimatedInfo: getEstimatedInfo = null,
  salePriceUsdAmount: salePriceUsdAmount = 0,
  lang: lang = '',
  parentCats: parentCats = null
}) => {

  let reason = []
  let otherResionResult = {
    reason: '',
    isShow: false
  }

  let { Buynowfreeship, Buynowthreshold, DetailBuynowtsplabel, DetailBuynow } = DetailBuynowAbt || {
    Buynowfreeship: 'Unlimited',
    Buynowthreshold: '',
    DetailBuynowtsplabel: '',
    DetailBuynow: 'Unshow',
  }

  // 非一键购 非定制商品 非售罄
  if (isSoldOut) return otherResionResult
  if (oneClickPayStateSupport) return otherResionResult
  if (isCustomization) return otherResionResult

  // TSP命中
  if (
    DetailBuynowtsplabel &&
    DETAIL_BUY_NOW_TSP_LABEL &&
    Object.keys(DETAIL_BUY_NOW_TSP_LABEL).length
  ) {
    let labels = DETAIL_BUY_NOW_TSP_LABEL[DetailBuynowtsplabel]
    let hasMatch = false
    for (let i of labels) {
      if (tsp?.[i]) hasMatch = true
    }
    if (!hasMatch) reason.push(2)
  }

  // 满足免邮 ABT DetailBuynow.p.Buynowfreeship = Limit 必须免邮，DetailBuynow.p.Buynowfreeship = Unlimited(default) ，无需免邮
  if (Buynowfreeship === 'Limit' && !isFreeShippin) reason.push(0)

  // ABT 根据 DetailBuynow.p.Buynowthreshold 再获取客单价，再对比商品价格。无客单价则展示。有客单价则对比当前商品价格，商品价格需低于客单价
  if (Buynowthreshold && BUY_NOW_THRESHOLD && BUY_NOW_THRESHOLD?.[Buynowthreshold]) {
    let cats = BUY_NOW_THRESHOLD?.[Buynowthreshold].catIds || []
    let curCats
    if (cacheCats[goodsId]) {
      curCats = cacheCats[goodsId]
    } else {
      curCats = getAllCatIdsFromParentsTree(parentCats) || []
      cacheCats[goodsId] = curCats
    }
    let hitCats = false
    if (cats.length) {
      cats.forEach(catid => {
        if (curCats.includes(catid)) hitCats = true
      })
    } else {
      hitCats = true
    }
    if (!hitCats) {
      reason.push(1)
    } else {
      const threshold = cats.length ? (BUY_NOW_THRESHOLD?.[Buynowthreshold]?.[lang] || -Infinity) : -Infinity
      let currentPrice = salePriceUsdAmount
      if (getEstimatedInfo?.value?.amount && (getEstimatedInfo?.isSatisfied || getEstimatedInfo.isEstimatedstrategyNone || getEstimatedInfo.isAbCoexist)) {
        currentPrice = getEstimatedInfo?.value?.usdAmount
      }
      if (parseFloat(currentPrice) < threshold) reason.push(1)
    }
  }

  // ABT DetailBuynow.p.DetailBuynow.p = Show (default Unshow)
  if (DetailBuynow !== 'Show') {
    if (reason.length == 0 && DetailBuynow === 'Unshow') triggerBuyNowEligshow(goodsId)
    return otherResionResult
  }
  return {
    reason: reason.join('`'),
    isShow: reason.length == 0
  }
}
